import React from 'react';

function ObfuscatedEmail() {
  const user = 'info';
  const domain = 'wishingwelltoken.com';
  const email = `${user}@${domain}`;

  return (
    <div className="contact-email">
      Contact us: <span>{email}</span>
    </div>
  );
}

export default ObfuscatedEmail;
