import React, { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers';
import './App.css';
import wellImage from './media/wishingwell.png';
import acknowledgeSound from './media/acknowledge.mp3';
import successSound from './media/success.mp3';
import ObfuscatedEmail from './components/ObfuscatedEmail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_URL = process.env.REACT_APP_API_URL;

const CONTRACT_ADDRESS = '0xB3Ab5eCA4DEA6ceaA0B61F6828d398CbA16a9652';
const CONTRACT_ABI = [
  {
    "constant": false,
    "inputs": [
      {
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "throwIntoWell",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "name": "balance",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  }
];

function App() {
  const [amount, setAmount] = useState('1');
  const [emailEnabled, setEmailEnabled] = useState(false);
  const [email, setEmail] = useState('');
  const [accountShort, setAccountShort] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [balance, setBalance] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);

  const acknowledgeAudioRef = useRef(null);
  const successAudioRef = useRef(null);

  const toggleAnswer1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleAnswer2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleAnswer3 = () => {
    setIsOpen3(!isOpen3);
  };

  const toggleAnswer4 = () => {
    setIsOpen4(!isOpen4);
  };

  async function connectAndFetchBalance() {
    try {
      if (!window.ethereum) {
        setError('MetaMask is not installed!');
        return;
      }
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];
      setAccountShort(account.substring(account.length - 5));
      const provider = new ethers.BrowserProvider(window.ethereum);
      setProvider(provider);
      const signer = await provider.getSigner();
      setSigner(signer);
      const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
      const balance = await contract.balanceOf(account);
      const formattedBalance = ethers.formatUnits(balance, 18);
      setBalance(formattedBalance);
      console.log(`Balance for ${account}: ${formattedBalance} WWT`);
    } catch (error) {
      console.error('Failed to fetch balance:', error);
      setError('Failed to fetch balance. See console for more details.');
    }
  }

  useEffect(() => {
    connectAndFetchBalance();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate email if checkbox is enabled
    if (emailEnabled && !validateEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!provider || !signer) {
      toast.error('MetaMask is not connected');
      return;
    }

    setIsSubmitting(true);

    try {
      acknowledgeAudioRef.current.play();
      // Interact with your ERC-20 contract to burn tokens using throwIntoWell
      const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

      // Check balance
      const balance = await contract.balanceOf(await signer.getAddress());
      const formattedBalance = ethers.formatUnits(balance, 18);

      if (parseFloat(formattedBalance) < parseFloat(amount)) {
        toast.error('Insufficient funds to throw the coin into the well.');
        setIsSubmitting(false);
        return;
      }

      const txResponse = await contract.throwIntoWell(ethers.parseUnits(amount, 18));
      const receipt = await txResponse.wait(); // Wait for the transaction to be mined

      if (receipt.status !== 1) {
        throw new Error("Transaction failed with status 0");
      }
    } catch (error) {
      console.error('Error during transaction:', error);
      toast.error('Failed to throw tokens into the well. Make sure you have WishingWell tokens in your wallet.');
      setIsSubmitting(false);  // Re-enable the button after the transaction
      return; // Return early to prevent further execution
    }

    try {
      successAudioRef.current.play();
      console.log('Sending request to the server...');
      const requestBody = emailEnabled ? { email } : {};
      const response = await fetch(`${API_URL}/drop`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      if (data.message) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error post-transaction:', error);
      toast.error('An error occurred reaching the backend.');
    } finally {
      setIsSubmitting(false);  // Re-enable the button after all processes
      connectAndFetchBalance(); // Update balance regardless of success
    }
  };

  const handleInfoClick = () => {
    const message = "To acquire Wishing Well Tokens, visit the Uniswap exchange where you can trade for them using Ethereum. Follow this link for more details and to trade now: ";
    const link = "https://uniswap.org";
    toast.error(`${message}\n${link}`);
};

const handleCheckboxChange = () => {
    setEmailEnabled(!emailEnabled);
  };

const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
};

return (
    <div className="App">
      <div className="container">
        <header className="header">
          <h1>Wishing Well</h1>
        </header>
        <main className="main">
          <div className="section well-section">
            <div className="card">
              <img src={wellImage} alt="Wishing Well" className="wellImage"/>
              <p className="caption">Try your luck for a prediction</p>

              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={emailEnabled}
                  onChange={handleCheckboxChange}
                />
                Send prediction to a friend/person
              </label>
              {emailEnabled && (
                <input
                  style={{marginTop:"20px"}}
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Enter email address"
                  className="inputField"
                />
              )}

              <form onSubmit={handleSubmit} className="form" style={{marginTop:"20px"}}>
                <button type="submit" disabled={isSubmitting} className="info-button">
                  {isSubmitting ? 'Processing...' : 'Drop Coin'}
                </button>
                <audio ref={acknowledgeAudioRef} src={acknowledgeSound} preload="auto" />
                <audio ref={successAudioRef} src={successSound} preload="auto" />
              </form>
              <div className="balance-display">
                {error ? <p className="error">{error}</p> : (
                  <>
                    <p><span className="bold">Account:</span> ...{accountShort}</p>
                    <p><span className="bold">Balance:</span> {balance} WWT</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="section project-info">
            <h2 style={{marginBottom: "40px", color:"white"}}>About the Project</h2>
            <div className="info-grid">

              <div className="info-box">
                <h3>What is Wishing Well Token?</h3>
                <p> Wishing Well Token (WWT) is the latest meme crypto coin designed to bring fun and fortune
                  to your digital wallet. By throwing a coin into our virtual wishing well, users can
                  receive delightful fortune cookie-like predictions about their future.
                  Each coin thrown into the well is burned, creating a deflationary effect and
                  increasing the value of the remaining tokens. Join the WWT community and experience
                  the thrill of crypto with a touch of magic.</p>
              </div>

              <div className="info-box">
                <h3>Send Predictions to Loved Ones</h3>
                <p>With Wishing Well Token, you can send a prediction to your friends,
                  lovers, or relatives by throwing a coin in the well on their behalf. Whether it's for
                  a birthday, a surprise, or just to cheer someone up, sending a WWT prediction is a unique
                  and heartfelt way to show appreciation. Make someone's day special with a personalized prediction
                  delivered straight to their email.</p>
              </div>

              <div className="info-box">
                <h3>Join the Fun and Create Value</h3>
                <p>Wishing Well Token is not just about fun and predictions; it’s about
                  creating value. By burning tokens with each wish, WWT ensures a decreasing supply, potentially
                  increasing the value of each remaining token. Participate in our vibrant community, enjoy the
                  whimsical predictions, and watch as your contributions help grow the value of WWT. Be part of
                  a crypto project that blends entertainment with financial potential.</p>
              </div>
            </div>
          </div>
          <div className="section faq">
            <h2>FAQ</h2>

            <div className="faq-item">
              <div className="faq-question" onClick={toggleAnswer1}>
                <span className="faq-icon">+</span> What is the Wishing Well?
              </div>
              <div className="faq-answer">
                {isOpen1 && <div className="faq-answer">The Wishing Well is a virtual wishing well where users can throw Wishing Well Tokens (WWT) to receive predictions or send them to loved ones.</div>}
              </div>
            </div>

            <div className="faq-item">
              <div className="faq-question" onClick={toggleAnswer4}>
                <span className="faq-icon">+</span> How does the Wishing Well work ?
              </div>
              <div className="faq-answer">
                {isOpen4 && <div className="faq-answer">Drop a coin in the Wishing Well and receive a prediction.</div>}
              </div>
            </div>

            <div className="faq-item">
              <div className="faq-question" onClick={toggleAnswer2}>
                <span className="faq-icon">+</span> Where can I buy is Wishing Well tokens ?
              </div>
              <div className="faq-answer">
                {isOpen2 && <div className="faq-answer">You can buy Wishing Well Tokens on the Uniswap exchange. Trade your Ethereum for WWT using the provided link on the homepage for more details."
                  </div>}
              </div>
            </div>

            <div className="faq-item">
              <div className="faq-question" onClick={toggleAnswer3}>
                <span className="faq-icon">+</span> What kind of token is Wishing Well token ?
              </div>
              <div className="faq-answer">
                {isOpen3 && <div className="faq-answer">The Wishing Well token is intended as a meme token with a fun use case.</div>}
              </div>
            </div>

          </div>
        </main>
        <footer className="footer">
          <ObfuscatedEmail className="contact-email"/>
        </footer>
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
